<template>
  <v-container>
    <v-row
      v-if="alertLoader || !alert.id"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col cols="12">
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
      <v-col class="subtitle-1 text-center" cols="12"
        >Chargement de l'alerte...</v-col
      >
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-btn @click="back">
              <v-icon left> mdi-arrow-left </v-icon>
              {{ $t("back") }}
            </v-btn>
          </v-col>

          <AlertOptions />
        </v-row>

        <AlertDetail :alert="alert" />

        <AlertCumul
          v-if="alert.previous && alert.previous.length > 0"
          :alertData="alert"
        />

        <AlertEvolution
          v-if="
            alert.previous &&
            alert.previous.length > 0 &&
            alert.triggered_duration > 1 &&
            alert.rule.meta.chart
          "
          :alertData="alert"
        />

        <Comments />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { formatAlert } from "../services/alert";

export default {
  components: {
    AlertOptions: () => import("@/components/AlertDetails/AlertOptions"),
    AlertDetail: () => import("@/components/AlertDetails/AlertDetail"),
    AlertCumul: () => import("@/components/AlertDetails/AlertTableCumul"),
    AlertEvolution: () => import("@/components/AlertDetails/AlertEvolution"),
    Comments: () => import("@/components/Comments/Timeline"),
  },
  computed: {
    ...mapState({
      alertLoader: (state) => state.alerts.alertLoader,
      alert: (state) =>
        formatAlert(state.alerts.alertDetail, {
          queries: state.rules.rulesNames,
        }),
    }),
  },
  async beforeCreate() {
    await this.$store.dispatch("rules/loadRulesNames");
    this.$store.dispatch("alerts/getAlertDetail", this.$route.params.id);
  },
  methods: {
    back: function () {
      this.$router.push({
        name: "Alerts",
        params: { expand: this.alert.rule_id },
      });
    },
  },
  watch: {
    alert: function (val) {
      if (val.previous && val.previous.length > 0) {
        const lastPreviousAlertDate = val.previous.slice(-1)[0].date;

        this.$store.dispatch("alerts/getSimilarAlerts", {
          key: val.key,
          ruleId: val.rule_id,
          latestDate: lastPreviousAlertDate,
        });
      }
    },
  },
  destroyed() {
    this.$store.commit("alerts/SET_ALERT_DETAIL", {});
    this.$store.commit("alerts/SET_ALERT_COMMENTS", []);
  },
};
</script>
